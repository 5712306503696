<template>
  <!-- DASHBOARD COUNSELING FOR USER PUBLIC -->
  <div class="_bg-default _80w _100vh px-5 py-3">
    <div class="_100top">
      <div class="d-flex justify-end mb-3">
        <v-card
          width="250px"
          class="pa-3 radius-card d-flex justify-space-between"
          v-if="profile"
        >
          <div>
            <div class="d-flex align-center">
              <v-icon small class="mr-2">mdi-credit-card-check-outline</v-icon>
              <p class="ma-0 small_txt color_txt">Balance</p>
            </div>
            <b
              >Rp.
              {{
                profile.my_balance
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              }}
            </b>
          </div>
          <v-btn class="text-capitalize" to="/psikolog/balance" depressed>
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </v-card>
      </div>
      <v-card class="radius-card pa-2">
        <v-tabs v-model="tab" class="mb-5 radius-card" hide-slider grow>
          <v-tab
            href="#tab-1"
            class="text-capitalize mr-3 header_tab"
            dark
            active-class="active_tab"
            ><v-icon class="mr-2">mdi-comment</v-icon><b>All Session</b></v-tab
          >
          <!-- <v-tab
            href="#tab-2"
            class="text-capitalize mr-3 header_tab"
            active-class="active_tab"
            dark
            ><v-icon class="mr-2">mdi-credit-card-check</v-icon
            ><b>Session Done</b></v-tab
          > -->
          <v-tab
            href="#tab-2"
            class="text-capitalize mr-3 header_tab"
            active-class="active_tab"
            dark
            ><v-icon class="mr-2">mdi-cog</v-icon><b>Setting</b></v-tab
          >
        </v-tabs>

        <v-tabs-items v-model="tab" style="background: none">
          <v-tab-item value="tab-1">
            <sessionCounseling />
          </v-tab-item>
          <!-- <v-tab-item value="tab-2">
            <transaction-counseling />
          </v-tab-item> -->
          <v-tab-item value="tab-2">
            <set-counseling-time />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <div class="pa-2" v-if="profile"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sessionCounseling from "../../../../components/Counseling/Psycholog/sessionCounseling.vue";
import SetCounselingTime from "../../../../components/Counseling/Psycholog/setCounselingTime.vue";
// import TransactionCounseling from "../../../../components/Counseling/Psycholog/transactionCounseling.vue";
export default {
  components: {
    sessionCounseling,
    // TransactionCounseling,
    SetCounselingTime
  },
  name: "counselingPsydash",
  computed: {
    ...mapState({
      profile: state => state.psycholog.myProfile
    })
  },
  data() {
    return {
      tab: "tab-1"
    };
  },
  mounted() {
    this.checkReq();
  },
  methods: {
    checkReq() {
      this.$store.dispatch("counseling/chekRequest");
    }
  }
};
</script>

<style></style>
