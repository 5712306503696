/* eslint-disable */
<template>
  <!-- DASHBOARD COUNSELING FOR USER PUBLIC -->
  <div>
    <v-card
      v-if="loading"
      min-height="300px"
      class="radius-card d-flex align-center justify-center"
    >
      <v-progress-circular
        indeterminate
        color="purple"
        class="mr-3"
      ></v-progress-circular>
      <b>Loading</b>
    </v-card>
    <v-card min-height="300px" class="radius-card" v-if="trx && !loading">
      <div
        v-if="!trx.length"
        class="d-flex pa-2 justify-center align-center"
        style="height: 300px"
      >
        <div>
          <h3 class="color_txt">Tidak ada sesi yang sedang berjalan</h3>
        </div>
      </div>

      <div class="grey lighten-4" style="min-height: 300px" v-else>
        <div class="pa-3">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(item, i) in trx"
              :key="`sect_${i}`"
              elevation="1"
              class="rounded-lg mb-2"
            >
              <v-expansion-panel-header class="pa-3">
                <v-row>
                  <v-col cols="6">
                    <div class="d-flex align-center">
                      <v-avatar size="40" class="mr-2">
                        <img
                          :src="item.public.photo ? item.public.photo : ava"
                          class="obj_fit"
                          alt="isyan"
                        />
                      </v-avatar>
                      <div>
                        <h3>
                          {{ item.public.nama_lengkap }}
                        </h3>
                        <p class="mb-0 small_txt">{{ item.id }}</p>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6">
                    <div class="d-flex justify-end mx-3">
                      <v-chip
                        v-if="item.status == 0"
                        color="yellow"
                        text-color="white"
                      >
                        Pending
                      </v-chip>
                      <v-chip
                        v-if="item.status == 1"
                        color="green"
                        text-color="white"
                      >
                        Approved
                      </v-chip>
                      <v-chip
                        v-if="item.status == 2"
                        color="red"
                        text-color="white"
                      >
                        Rejected
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex">
                  <div style="width: 35px"></div>
                  <div style="flex: 1">
                    <div class="mb-2">
                      <b class="mb-3">Tanggal dan Waktu Konsultasi (WIB)</b>
                      <v-card
                        outlined
                        v-for="(sesi, j) in item.sessions"
                        :key="`sesi-${j}`"
                        class="mb-2 pa-2"
                      >
                        <v-row>
                          <v-col cols="6">
                            <div class="d-flex">
                              <p class="mb-0 mr-2">
                                Sesi {{ sesi.session }} ({{
                                  sesi.schedule.duration
                                }}
                                menit)
                              </p>
                              <v-icon
                                color="green"
                                size="20"
                                v-if="sesi.schedule.is_done == 1"
                                >mdi-check-circle</v-icon
                              >
                            </div>
                            <div v-if="sesi.schedule.is_done == 1">
                              <div class="d-flex">
                                <v-card class="pa-2 mr-3" outlined>
                                  <v-icon color="#3b053c">mdi-clock</v-icon>
                                  <v-icon color="orange">mdi-star</v-icon>
                                  {{ sesi.schedule.rating_waktu }}
                                </v-card>
                                <v-card class="pa-2 mr-3" outlined>
                                  <v-icon color="#3b053c">mdi-head-cog</v-icon>
                                  <v-icon color="orange">mdi-star</v-icon>
                                  {{ sesi.schedule.rating_kualitas }}
                                </v-card>
                                <v-card class="pa-2 mr-3" outlined>
                                  <v-icon color="#3b053c">mdi-thumb-up</v-icon>
                                  <v-icon color="orange">mdi-star</v-icon>
                                  {{ sesi.schedule.rating }}
                                </v-card>
                              </div>
                              <p class="mb-0 small_txt">
                                reflection : <br />
                                {{ sesi.schedule.reflection_desc }}
                              </p>
                            </div>
                            <div v-if="sesi.schedule.is_done == 0">
                              <b v-if="sesi.schedule.booking_time">
                                {{
                                  $date(sesi.schedule.booking_time).format(
                                    "dddd, DD MMMM YYYY",
                                  )
                                }}
                                <br />
                                {{
                                  $date(sesi.schedule.booking_time).format(
                                    "HH:mm",
                                  )
                                }}
                                (WIB)
                              </b>
                              <p
                                class="mb-0 small_txt grey--text"
                                v-if="
                                  j !== 0 &&
                                    item.sessions[j - 1].schedule.is_done == 0
                                "
                              >
                                Belum ada jadwal.
                              </p>
                              <p
                                class="mb-0 small_txt grey--text"
                                v-if="
                                  j !== 0 &&
                                    item.sessions[j - 1].schedule.is_done ==
                                      1 &&
                                    !sesi.schedule.booking_time
                                "
                              >
                                Client belum mengatur jadwal
                              </p>
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <!-- v-if="item.status == 1" -->
                            <div class="d-flex justify-end">
                              <v-btn
                                v-if="sesi.schedule.is_done == 0"
                                :to="`/chat/${item.id}`"
                                depressed
                                :disabled="!sesi.schedule.booking_time"
                                color="blue"
                                dark
                                ><v-icon class="mr-2">mdi-chat</v-icon>
                                Chat</v-btn
                              >
                              <v-btn
                                v-if="sesi.schedule.is_done == 1"
                                :to="
                                  `/history-chat/${item.id}/${sesi.konsultasi_schedule_id}`
                                "
                                depressed
                                color="blue"
                                dark
                                ><v-icon class="mr-2">mdi-chat</v-icon> Riwayat
                                Chat</v-btn
                              >
                            </div>
                          </v-col>
                        </v-row>
                      </v-card>
                    </div>
                    <div class="d-flex align-center justify-space-between">
                      <v-btn
                        @click="getMasalah(item)"
                        color="#3B053C"
                        dark
                        depressed
                        >Lihat Permasalahan</v-btn
                      >
                      <div>
                        <p class="mb-0">Harga</p>
                        <b>
                          Rp{{
                            item.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                          }}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="pa-2 d-flex justify-center">
            <v-card outlined class="pa-2 d-flex align-center">
              <v-btn
                min-width="10px"
                small
                dark
                color="#3b053c"
                @click="paginate('prev')"
                ><v-icon small>mdi-arrow-left</v-icon></v-btn
              >
              <div class="mx-3 small_txt">{{ page }} of {{ last_page }}</div>
              <v-btn
                min-width="10px"
                small
                dark
                color="#3b053c"
                @click="paginate('next')"
                ><v-icon small>mdi-arrow-right</v-icon></v-btn
              >
            </v-card>
          </div>
        </div>
      </div>
      <div class="pa-2">
        <ViewMasalah
          :d_masalah="d_masalah"
          :masalah="masalah"
          @close="d_masalah = false"
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ViewMasalah from "./Modal/viewMasalah.vue";
export default {
  name: "activeSessionPsy",
  components: { ViewMasalah },
  data() {
    return {
      loading: false,
      dateNow: this.$date().format("YYYY-MM-DD HH:MM:ss"),
      header: [
        {
          txt: "No Transaksi",
          col: 2,
        },
        {
          txt: "Sesi ke",
          col: 1,
        },
        {
          txt: "Waktu Konseling (WIB)",
          col: 3,
        },
        {
          txt: "Status",
          col: 2,
        },
        {
          txt: "",
          col: 2,
        },
        {
          txt: "Detail",
          col: 1,
        },
      ],
      trx: null,
      page: 1,
      limit: 10,
      total: null,
      last_page: null,
      d_masalah: false,
      masalah: "",
    };
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
      ava: (state) => state.dummy,
    }),
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.$store.dispatch("counseling/listTransactionPsy").then((data) => {
        this.trx = data.data;

        this.total = data.total;
        this.last_page = data.last_page;
        this.loading = false;
      });
    },
    getMasalah(item) {
      if (item.sessions.length) {
        this.masalah = item.sessions[0].schedule.permasalahan;
        this.d_masalah = true;
      }
    },
  },
};
</script>

<style></style>
