<template>
  <div>
    <div class="my-3 d-flex align-center">
      <v-icon class="mr-2">mdi-cog</v-icon>
      <h2>Schedule</h2>
    </div>
    <v-card
      flat
      class="radius-card _bg-default pa-3"
      style="border-radius: 13px !important"
    >
      <div class="d-flex" v-if="isVerified">
        <div
          class="days_card mx-1"
          v-for="(item, idx) in isVerified.schedule"
          :key="idx"
        >
          <v-card class="rounded-lg mb-3 pa-2" flat>
            <div class="mb-3 text-center">
              <b> {{ item.day }}</b>
            </div>

            <div v-for="(h, i) in item.time" :key="`${i}-h`">
              <div class="h_temp">
                <p class="color_txt ma-0">{{ h }}</p>
              </div>
            </div>
            <v-btn
              depressed
              color="purple"
              @click="updateAvail(item)"
              block
              class="mt-5"
              dark
            >
              <p class="ma-0" v-if="item.time.length">Update</p>
              <p class="ma-0" v-else>Add</p>
            </v-btn>
          </v-card>
        </div>
        <div v-if="!isVerified.schedule.length">
          <v-btn depressed color="white" @click="startSet">
            <v-icon class="mr-2">mdi-cog</v-icon> Mulai Setting Jadwal
          </v-btn>
        </div>
      </div>
    </v-card>
    <div class="pa-3">
      <add-available-time
        v-bind:dialogAvail="dialogAvail"
        v-bind:day="itemDay"
        @close="dialogAvail = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import addAvailableTime from "./Modal/addAvailableTime.vue";
export default {
  components: { addAvailableTime },
  name: "setAvailableTime",
  data() {
    return {
      transaction: null,
      dialogAvail: false,
      itemDay: null,
      listDay: ["Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu", "Minggu"]
    };
  },
  computed: {
    ...mapState({
      isVerified: state => state.counseling.isVerified
    })
  },
  mounted() {
    this.checkReq();
  },
  methods: {
    startSet() {
      this.listDay.forEach(el => {
        console.log(el);
        let data = {
          day: el,
          time: []
        };
        this.$store.dispatch("counseling/setAvailTime", data).then(() => {
          this.checkReq();
        });
      });
    },
    checkReq() {
      this.$store.dispatch("counseling/chekRequest");
    },
    updateAvail(item) {
      this.itemDay = item;
      this.dialogAvail = true;
    }
  }
};
</script>

<style scoped>
.days_card {
  width: calc(100% / 7);
}
.h_temp {
  background: #f5f5f5;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
.bullet_title {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #8433bd;
}
</style>
