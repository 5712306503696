<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAvail" persistent max-width="400px">
      <v-card class="radius-card hide_overflow" v-if="day">
        <v-toolbar class="bg_none" dense flat>
          <p class="small_txt ma-0">
            <b> Available Time</b>
          </p>
          <v-spacer></v-spacer>
          <v-btn fab x-small color="white" depressed @click="$emit('close')"
            ><v-icon small color="red">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <div>
          <div class="pa-3" v-if="times">
            <v-btn-toggle
              v-model="day.time"
              active-class="active_time"
              borderless
              multiple
            >
              <div class="d-flex flex-wrap">
                <v-btn
                  class="ma-2"
                  depressed
                  v-for="(item, idx) in times"
                  :key="idx"
                  :value="item.time_from"
                >
                  {{ item.time_from }}
                </v-btn>
              </div>
            </v-btn-toggle>
          </div>
        </div>

        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="purple"
          ></v-progress-circular>
          <b class="purple--text">Loading...</b>
        </v-overlay>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            @click="setTime"
            dark
            color="purple"
            depressed
            >Simpan</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "availbalbeDay",
  props: ["dialogAvail", "day"],
  data() {
    return {
      time: null,
      menu2: false,
      loading: false,
      times: null,
      toggle_exclusive: []
    };
  },
  mounted() {
    this.getTimeFromBe();
  },
  methods: {
    pushTo(item) {
      this.day.time.push(item);
    },
    removeTime(item) {
      let arr = this.day.time;
      let index = arr.indexOf(item);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    getTimeFromBe() {
      this.$store.dispatch("counseling/getTime").then(data => {
        this.times = data;
      });
    },
    setTime() {
      this.loading = true;
      let data = {
        day: this.day.day,
        time: this.day.time
      };
      this.$store.dispatch("counseling/setAvailTime", data).then(data => {
        this.loading = false;
        this.$emit("close");
        Swal.fire({
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          position: "top",
          toast: true,
          timer: 3000
        });
      });
    }
  }
};
</script>

<style>
.active_time {
  background: #9c27b0 !important;
  color: #fff !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
