<template>
  <v-dialog v-model="d_masalah" persistent scrollable max-width="500px">
    <v-card class="radius-card hide_overflow" v-if="masalah">
      <v-toolbar class="bg_none" dense flat>
        <p class="small_txt ma-0">
          <b> Permasalahan</b>
        </p>
        <v-spacer></v-spacer>
        <v-btn fab x-small color="white" depressed @click="$emit('close')"
          ><v-icon small color="red">mdi-close</v-icon></v-btn
        >
      </v-toolbar>
      <div class="pa-3">
        {{ masalah }}
      </div>
      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "viewMasalah",
  props: ["d_masalah", "masalah"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style></style>
